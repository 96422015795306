import React from "react";
import { graphql } from 'gatsby';

import { helmetPageValues as hel } from '../utils/constants';
import { Layout, LandingLayout } from '../layouts';
import { GridLeft, GridText, GridRight, Helmet } from '../components';

const Heading = () => <span>Services for<br />your digital world</span>;

const paragraph = `Our team of developers and marketing spetialist will create a unique digital expresion of your business. Your companies internet presence is our concern.`;

const IndexPage = ({ data: { fluid: { childImageSharp } }}) => {
  return (
    <Layout>
      <Helmet {...hel.home} />
      <LandingLayout>
        <GridLeft image={childImageSharp.fluid} />
        <GridText heading={<Heading />} nodes={['Websites', 'Design', 'Marketing']} paragraph={paragraph} />
        <GridRight />
      </LandingLayout>
    </Layout>
  )
}

export const query = graphql`
  {
    fluid: file(relativePath: {eq: "office.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
